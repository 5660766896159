import React, { Component } from "react";
import * as Services from "../../services";
import { Container, Card } from "react-bootstrap";
import NavbarCp from "../../components/NavbarCp";
import CarouselCp from "../../components/CarouselCp";
import EventCp from "../../components/EventCp";
import GameCp from "../../components/GameCp";
import Game2Cp from "../../components/Game2Cp";
import ChatUs from "../../partials/chatUs";
import NavPillCp from "../../components/NavPillCp";
import NavPillCp2 from "../../components/NavPillCp2";
import FooterCp from "../../components/FooterCp";
import { Helmet, HelmetProvider } from "react-helmet-async";
import swal from "sweetalert";

class Home extends Component {
  // initialize data
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      category: [],
      gamesEvent: [],
      games1: [],
      games2: [],
      headTitle: {
        Title: "Top Up Game Murah & Cepat Hanya di  ",
        Owner: "Mobacash",
      },
      headG: {
        heading: "Mobile Game",
        subHeading: "Game Mobile",
      },
      headPc: {
        heading: "PC Game",
        subHeading: "Game PC",
      },
      headN: {
        heading: "Berita Terbaru",
        subHeading: "Berita Game Terbaru",
      },
      notFound1: 0,
      notFound2: 0,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.createRequestEvent();
    this.createRequest1();
    this.createRequest2();
    this.createRequestCate();
    this.detailSite();
  }

  // Fetch data (get data from backend)
  detailSite = async () => {
    fetch(`${process.env.REACT_APP_BASE_URL}/data/setting`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + process.env.REACT_APP_TOKEN,
      },
    })
      .then((r) => r.json().then((data) => ({ status: r.status, data: data.data })))
      .then((data) => {
        this.site = data.data[0];
        this.setState({
          title: data.data[0].title,
        });
      })
      .catch((err) => {
        swal(err.message, {
          icon: "error",
          button: true,
        });
      });
  };

  createRequestCate = async (cate) => {
    if (cate) {
      let category = await Services.loadCate(cate);
      this.setState({
        category: category || [],
      });
    } else {
      let category = await Services.loadCate();
      this.setState({
        category: category || [],
      });
    }
  };
  createRequestEvent = async (cate) => {
    if (cate) {
      let game = await Services.loadEvent(cate);
      this.setState({
        gamesEvent: game,
        notFound1: game.length === 0 ? 1 : 0,
      });
    } else {
      let game = await Services.loadEvent("");
      this.setState({
        gamesEvent: game,
      });
    }
  };
  createRequest1 = async (cate) => {
    if (cate) {
      let game = await Services.loadGames(cate);
      this.setState({
        games1: game,
        notFound1: game.length === 0 ? 1 : 0,
      });
    } else {
      let game = await Services.loadGames("popular");
      this.setState({
        games1: game,
      });
    }
  };
  createRequest2 = async (cate) => {
    if (cate) {
      let game = await Services.loadGames(cate);
      this.setState({
        games2: game,
        // category: category || [],
        notFound2: game.length === 0 ? 1 : 0,
      });
    } else {
      let game = await Services.loadGames();

      this.setState({
        games2: game,
      });
    }
  };

  // render elment,data
  render() {
    // function cate for get game(mobile/trending/pc)

    const reloadRequest1 = (cate) => {
      this.createRequest1(cate);
    };
    const reloadRequest2 = (cate) => {
      this.createRequest2(cate);
      this.createRequestCate(cate);
    };
    return (
      <HelmetProvider>
        <Helmet prioritizeSeoTags>
          <meta charset="UTF-8" />
          <meta http-equiv="X-UA-Compatible" content="IE=edge" />
          <meta content="yes" name="apple-mobile-web-app-capable" />
          <meta name="viewport" content="width=device-width, minimum-scale=1, maximum-scale=1, initial-scale=1, user-scalable=no" />
          <title>{this.state.title} - Website topup termurah dan terbaik</title>
          <meta name="application-name" content={this.state.title} />
          <meta name="copyright" content={`© 2023 by ${this.state.title}`} />
          <meta name="description" content={`Top up or buy game credits/diamonds/game voucher, fast and cheap at ${this.state.title}. ${this.state.title} is the best way to buy game credits/game vouchers.`} />
          <meta name="title" content={`${this.state.title} - Website topup termurah dan terbaik`} />
          <meta name="robots" content="noodp, noydir" />
          <meta name="theme-color" content="#ff962d" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content={`${this.state.title} ${this.state.title} - Cheapest and Fastest Online Game Voucher`} />
          <meta property="og:description" content={`Top up or buy game credits/diamonds/game voucher, fast and cheap at ${this.state.title}. ${this.state.title} is the best way to buy game credits/game vouchers.`} />
          <meta property="twitter:card" content="summary" />
          <meta property="twitter:site" content={`@${this.state.title.toLowerCase()}`} />
          <meta property="twitter:title" content={`${this.state.title} ${this.state.title} - Cheapest and Fastest Online Game Voucher`} />
          <meta property="twitter:description" content={`Top up or buy game credits/diamonds/game voucher, fast and cheap at ${this.state.title}. ${this.state.title} is the best way to buy game credits/game vouchers.`} />
        </Helmet>
        <div className="set-height">
          <Container>
            <NavbarCp data={this.site} />
            <CarouselCp data={this.site} />
          </Container>
          <Container className="">
            <EventCp data={this.state.gamesEvent} notFound={this.state.notFound} />
            <Card className="border-0 br-15 box-shadow my-4 ">
              <Card.Body>
                <NavPillCp func={reloadRequest1} />
                <GameCp data={this.state.games1} notFound={this.state.notFound} />
              </Card.Body>
            </Card>
            <NavPillCp2 func={reloadRequest2} category={this.state.category} />
            <Game2Cp data={this.state.games2} notFound={this.state.notFound2} />
          </Container>
          <ChatUs data={this.site} />
          <FooterCp data={this.site} />
        </div>
      </HelmetProvider>
    );
  }
}

export default Home;
