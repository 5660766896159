import React from "react";
import { Col, Modal, Button, Row } from "react-bootstrap";
import "./ModalCp.css";

const ModalCp = (props) => {
  const data = {};
  Object.keys(props.formState).forEach(function (key) {
    if (key !== "game" && key !== "length") {
      if ((Number.isInteger(parseFloat(props.formState[key])) && props.formState[key].length < 6) || !Number.isInteger(parseFloat(props.formState[key]))) {
        data.serverid = props.formState[key];
      } else {
        data.userid = props.formState[key];
      }
    }
  });

  return (
    <Modal show={props.show} onHide={props.handleClose} centered backdrop="static" keyboard={false}>
      <div className="">
        <Modal.Header closeButton>
          <Modal.Title>
            Detail Pesanan
            <p className="fz-14 fw-normal">Pastikan data akun Anda dan produk yang Anda pilih valid dan sesuai.</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="">
          <Row>
            <Col xs={5} className="d-flex justify-content-sm-start mb-2">
              <span className="text-dark fs-6"> {props.usernm ? "Username" : ""}</span>
            </Col>
            <Col xs={7}>
              <span className="text-dark fs-6" usernm={props.usernm}>
                : {props.usernm}
              </span>
            </Col>
            <Col xs={5} className="d-flex justify-content-sm-start mb-2">
              <span className="text-dark fs-6">ID Game</span>
            </Col>
            <Col xs={7}>
              <span className="text-dark fs-6">
                : {data.userid} {data.serverid ? `(${data.serverid})` : ""}
              </span>
            </Col>
            <Col xs={5} className="d-flex justify-content-sm-start mb-2">
              <span className="text-dark fs-6">No.WA</span>
            </Col>
            <Col xs={7}>
              <span className="text-dark fs-6" wa={props.wa}>
                : {props.wa}
              </span>
            </Col>
            <Col xs={5} className="d-flex justify-content-sm-start mb-2">
              <span className="text-dark fs-6">Nama Item</span>
            </Col>
            <Col xs={7}>
              <span className="text-dark fs-6" nameproduct={props.nameproduct}>
                : {props.nameproduct}
              </span>
            </Col>
            <Col xs={5} className="d-flex justify-content-sm-start mb-2">
              <span className="text-dark fs-6">Metode Pembayaran</span>
            </Col>
            <Col xs={7}>
              <span className="text-dark fs-6" payment={props.payment}>
                : {props.payment}
              </span>
            </Col>
            <Col xs={5} className="d-flex justify-content-sm-start mb-2">
              <span className="text-dark fs-6">Jumlah</span>
            </Col>
            <Col xs={7}>
              <span className="text-dark fs-6" paymentused={props.paymentused} paymentref={props.paymentref}>
                : {props.price}
              </span>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button className="btn-purple2 px-4" type="submit" variant="" onClick={props.handleSubmit}>
            Pesan sekarang!
          </Button>
          <Button className="px-5" variant="secondary" onClick={props.handleClose}>
            Batalkan
          </Button>
        </Modal.Footer>
      </div>
    </Modal>
  );
};

export default ModalCp;
