import swal from "sweetalert";
import authorizeToken from "./token";

export const loadEvent = async (category) => {
  let url = `${process.env.REACT_APP_BASE_URL}/data/event`;

  try {
    const response = await fetch(url, authorizeToken);
    const data = await response.json();
    return data ? data.data : [];
  } catch (err) {
    swal(err.message, {
      icon: "error",
      button: true,
    });
    return [];
  }
};
export const loadGames = async (category) => {
  let url = `${process.env.REACT_APP_BASE_URL}/data/game`;
  url += !category ? `` : category === "popular" ? `?for=popular` : category === "new" ? `?for=new` : `?for=category&q=${category}`;

  try {
    const response = await fetch(url, authorizeToken);
    const data = await response.json();
    return data ? data.data : [];
  } catch (err) {
    swal(err.message, {
      icon: "error",
      button: true,
    });
    return [];
  }
};
export const loadCate = async () => {
  let url = `${process.env.REACT_APP_BASE_URL}/data/category`;

  try {
    const response = await fetch(url, authorizeToken);
    const data = await response.json();
    return data.data;
  } catch (err) {
    swal(err.message, {
      icon: "error",
      button: true,
    });
    return [];
  }
};

export const searchGames = async (name) => {
  let url = `${process.env.REACT_APP_BASE_URL}/data/game?for=search&q=${name}`;

  try {
    const response = await fetch(url, authorizeToken);
    const data = await response.json();
    return data.data;
  } catch (err) {
    swal(err.message, {
      icon: "error",
      button: true,
    });
    return [];
  }
};

export const listTrx = async (phone) => {
  let url = `${process.env.REACT_APP_BASE_URL}/data/trx?q=${phone}`;

  try {
    const response = await fetch(url, authorizeToken);
    const data = await response.json();
    return data.data;
  } catch (err) {
    swal(err.message, {
      icon: "error",
      button: true,
    });
    return [];
  }
};
export const DetailInvoice = async (reff) => {
  const [, ref] = reff.split("_");
  let url = `${process.env.REACT_APP_BASE_URL}/data/trx?q=${ref}`;

  try {
    const response = await fetch(url, authorizeToken);
    const data = await response.json();
    return data.data;
  } catch (err) {
    swal(err.message, {
      icon: "error",
      button: true,
    });
    return [];
  }
};
