import React from "react";
import { Nav } from "react-bootstrap";
import "./NavPillCp.css";

const NavPillCp = ({ func }) => {
  const handleSelect2 = (eventKey) => {
    func(eventKey);
  };
  return (
    <>
      <Nav id="navId" className=" mx-auto mt-4 ps-2 ps-md-3 text-center gap-2 gap-md-3 " variant="tabs" defaultActiveKey="popular" onSelect={handleSelect2}>
        <Nav.Item>
          <Nav.Link className="nav-text " href="#" eventKey="popular">
            Game Popular
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link className="nav-text" eventKey="new">
            Game New
          </Nav.Link>
        </Nav.Item>
      </Nav>
    </>
  );
};

export default NavPillCp;
