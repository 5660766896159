import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Topup from "./pages/Topup";
import CheckTrx from "./pages/CheckTrx";
import Success from "./pages/Success";
import Notfound from "./pages/Notfound";
import { Helmet, HelmetProvider } from "react-helmet-async";
import swal from "sweetalert";

const App = () => {
  const [favicon, setFavicon] = useState("");
  useEffect(() => {
    fetch(`${process.env.REACT_APP_BASE_URL}/data/setting`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + process.env.REACT_APP_TOKEN,
      },
    })
      .then((r) =>
        r.json().then((data) => ({ status: r.status, data: data.data }))
      )
      .then((data) => {
        setFavicon(data.data[0].favicon);
      })
      .catch((err) => {
        swal(err.message, {
          icon: "error",
          button: true,
        });
      });
  }, []);

  return (
    <HelmetProvider>
      <Helmet prioritizeSeoTags>
        <link rel="icon" href={`${favicon}`} />
        <link rel="apple-touch-icon" href={`${favicon}`} />
      </Helmet>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/topup/*" element={<Topup />} />
          <Route path="/cek-pesanan" element={<CheckTrx />} />
          <Route path="/success" element={<Success />} />
          <Route path="*" element={<Notfound />} />
        </Routes>
      </Router>
    </HelmetProvider>
  );
};

export default App;
