import React, { useEffect, useState } from "react";
import "./NavbarCp.css";
import { Container, Form, Nav, Offcanvas, Navbar } from "react-bootstrap";
import SearchPart from "../../partials/search";
import { ThreeDots } from "react-loader-spinner";
import { Link, useLocation } from "react-router-dom";

const NavbarCp = (props) => {
  const [logo, setLogo] = useState([]);
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    if (props.data) {
      setLoading(false);
      setLogo(props.data.logo);
      setName(props.data.title);
    }
  }, [props]);
  const location = useLocation();

  const isActive = (path) => {
    return location.pathname === path;
  };
  return (
    <>
      <Navbar expand="lg" className=" text mx-2" variant="light" sticky="top">
        <Container className="navbarCustom">
          <Navbar.Brand className="d-flex justify-content-end align-items-center ">
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand`} className="border-0" />
            <Link to="/">
              {loading ? <ThreeDots height="30" width="30" radius="9" color="#4fa94d" ariaLabel="three-dots-loading" wrapperStyle={{}} wrapperClassName="" visible={true} /> : <img src={logo} alt="" className="logo mb-1" />}
            </Link>
            <span className="text-dark fs-6 ms-1 fw-semibold ">{name}</span>
          </Navbar.Brand>
          <Form className="d-flex d-lg-none ms-auto">
            <SearchPart />
          </Form>
          <Navbar.Offcanvas id={`offcanvasNavbar-expand`} aria-labelledby={`offcanvasNavbarLabel-expand`} placement="start">
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand`}>
                <Link to="/">
                  <img src={logo} alt={"Logo " + name} className="logo  " />
                </Link>
                <span className="text-dark fs-6 ms-1 fw-semibold ">{name}</span>
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <span className="borderNavbar d-none d-md-flex"></span>
              <Nav className=" d-flex gap-3 ms-md-4">
                <Link className={`my-auto link ${isActive("/") ? "text-purple " : ""}`} to="/">
                  <nav className="fz-14">Home</nav>
                </Link>
                <Link className={`my-auto link ${isActive("/cek-pesanan") ? "text-purple" : ""}`} to="/cek-pesanan">
                  <nav className="fz-14">Cek Transaksi</nav>
                </Link>
              </Nav>
              <Form className="d-none d-lg-flex py-1 ms-auto">
                <SearchPart />
              </Form>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </>
  );
};

export default NavbarCp;
