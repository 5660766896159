import React from "react";
import { Nav } from "react-bootstrap";
import "./NavPillCp2.css";

const NavPillCp2 = ({ func, category = [] }) => {
  const handleSelect2 = (eventKey) => {
    func(eventKey);
  };
  return (
    <>
      <Nav id="navId" className="container mx-auto mt-4 ps-3 text-center gap-2 gap-md-3" variant="tabs" defaultActiveKey="games" onSelect={handleSelect2}>
        {category.map((category, index) => (
          <Nav.Item key={category}>
            <Nav.Link className="nav-text" eventKey={category}>
              {category.charAt(0).toUpperCase() + category.slice(1).toLowerCase()}
            </Nav.Link>
          </Nav.Item>
        ))}
      </Nav>
    </>
  );
};

export default NavPillCp2;
