import React from "react";
import { Card, Row, Col } from "react-bootstrap";
import "./EventCp.css";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function formatRP(margin) {
  const formatted = new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 2,
  }).format(Math.round(margin));

  return formatted.replace(",00", "");
}
const EventCp = (props) => {
  const settings = {
    infinite: false,
    slidesToShow: 6,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 2000,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          // dots: true,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          // dots: true,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };
  return (
    <>
      {props.data.length === 0 || undefined || null || "" ? (
        <></>
      ) : (
        <Row className=" my-2 px-2 px-sm-0 gy-3">
          {props.data.map((event, eventIndex) => (
            <Col key={event._id} xl={12} lg={12} md={12} sm={12} xs={12}>
              <Card.Title>{event.title}</Card.Title>
              <Card.Text>{event.description}</Card.Text>
              {event.products && event.products.length > 0 && (
                <Slider {...settings}>
                  {event.products
                    .filter((val) => val.code)
                    .sort((a, b) => a.price - b.price)
                    .map((product, index) => (
                      <Col key={index} xl={4} lg={4} md={6} sm={6} xs={12}>
                        <Link to={product.type === "manual" ? product.url : "/topup/" + product.game.code + "?selected=" + product.code} className="link">
                          <Card className="cardEvent border-0 br-15 box-shadow my-2">
                            <Card.Body className="d-block align-items-center p-2">
                              <div className="d-flex">
                                <Card.Img className="my-auto imgGames" variant="top" src={product.game.icon} />
                                <div className="d-block mx-1 my-auto">
                                  <Card.Title className="title-product my-1 fw-semibold text-start">{product.name}</Card.Title>
                                  <Card.Title className="title-product my-1  text-start text-secondary">{product.game.name}</Card.Title>
                                </div>
                              </div>
                              <Card.Title className="title-product text-start ">
                                <p className="fw-semibold mt-2 mb-1">{formatRP(product.discountPrice)}</p>
                                <p className="mb-0 pb-0">
                                  <span className="badge-purple fz-10">{product.type === "flat" ? `- ${formatRP(product.discount)}` : `- ${product.discount}%`}</span>
                                  <span className="original-price mx-1 fz-12">{formatRP(product.price)}</span>
                                </p>
                              </Card.Title>
                            </Card.Body>
                          </Card>
                        </Link>
                      </Col>
                    ))}
                </Slider>
              )}
              <span className="fz-12 text-danger ms-3 mt-2">*Geser &#62;*</span>
            </Col>
          ))}
        </Row>
      )}
    </>
  );
};

export default EventCp;
