import React, { useEffect, useRef, useState } from "react";
import { BsSearch } from "react-icons/bs";
import "./search.css";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { searchGames } from "../../services";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import notFound from "../../assets/img/notFound.png";

const SearchPart = () => {
  const [show, setShow] = useState(false);
  const [state, setstate] = useState({
    query: "",
    list: [],
  });

  const handleClose = () => {
    setShow(false);
    state.list = [];
    state.query = "";
  };
  const handleShow = () => {
    setShow(true);
  };

  const innerRef = useRef();

  const location = useLocation();

  useEffect(() => {
    innerRef.current && innerRef.current.focus();
    setShow(false);
    state.list = [];
    state.query = "";
  }, [location]);

  const subject = new Subject();
  subject
    .asObservable()
    .pipe(debounceTime(500))
    .subscribe(async (e) => {
      const results = await searchGames(e);
      setstate({
        query: e,
        list: results,
      });
    });

  const handleKeyup = (e) => {
    subject.next(e.target.value);
  };

  return (
    <>
      <Row className="bg-search justify-content-between m-1 mx-0" onClick={handleShow}>
        <Col xs={2}>
          <span>
            <BsSearch />
          </span>
        </Col>
        <Col xs={10} className="d-none d-md-inline">
          <span>Cari Game...</span>
        </Col>
      </Row>
      <Modal dialogClassName="my-modal" show={show} onHide={handleClose}>
        <Form.Control type="search" placeholder="Cari Game..." aria-label="Search" ref={innerRef} autoFocus onKeyUp={handleKeyup} />
        <Modal.Body className="px-0">
          {state.query === "" ? (
            <div className="px-4 d-flex flex-column align-items-center">
              <span>Enter a search query...</span>
            </div>
          ) : state.list.length === 0 ? (
            <div className="px-4 d-flex  align-items-center">
              <img src={notFound} alt="Not Found" width={70} />
              <span className="text-dark text-center ms-3 ">Tidak ada hasil.</span>
            </div>
          ) : (
            state.list.map((item, index) => (
              <div key={index} className="hoverSearch px-4 d-flex flex-column align-items-center">
                <Link key={index} className="d-flex link w-100 my-2 cursor-pointer" to={"/topup/" + item.code}>
                <img src={item.icon} alt={item.name} className="item-icon" style={{ width: '46px', height: '46px', marginRight: '10px' }} />
                <span className="my-auto">{item.name}</span>
                </Link>
              </div>
            ))
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SearchPart;
