import React, { useEffect, useState } from "react";
import "./chatUs.css";
import { Container, Dropdown, DropdownButton } from "react-bootstrap";

const FooterCp = (props) => {
  const [sosmed, setSosmed] = useState([]);

  useEffect(() => {
    if (props.data) {
      setSosmed(props.data.contacts);
    }
  }, [props]);

  return (
    <>
      <Container fluid className="d-flex justify-content-end px-3 fixed-bottom ">
        <DropdownButton
          variant=""
          title={
            <span className="fz-14 fw-semibold text-white ">
              <i className="bi bi-headset my-auto me-1 "></i>
              CHAT US
            </span>
          }
          className="btnChat me-2  d-none d-md-flex"
        >
          {sosmed &&
            sosmed.map((item, index) => (
              <Dropdown.Item key={index} className="fw-semibold  btnItem" href={item.url} target="_blank">
                <i className={`bi bi-${item.name.toLowerCase()} fs-6`}></i> {item.name}
              </Dropdown.Item>
            ))}
        </DropdownButton>
        <DropdownButton
          variant=""
          title={
            <span className="fz-14 fw-semibold text-white ">
              <i className="bi bi-headset my-auto me-1 "></i>
            </span>
          }
          className="btnChat me-2 d-flex d-md-none"
        >
          {sosmed &&
            sosmed.map((item, index) => (
              <Dropdown.Item key={index} className="fw-semibold  btnItem" href={item.url} target="_blank">
                <i className={`bi bi-${item.name.toLowerCase()} fs-6`}></i> {item.name}
              </Dropdown.Item>
            ))}
        </DropdownButton>
      </Container>
    </>
  );
};

export default FooterCp;
