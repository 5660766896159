import React, { useEffect, useState, useRef, useCallback } from "react";
import { StackedCarousel, ResponsiveContainer } from "react-stacked-center-carousel";
import "./CarouselCp.css";

const CarouselCp = (props) => {
  const [slider, setSlider] = useState([]);
  const ref = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (props.data && Array.isArray(props.data.slider)) {
      let data = props.data.slider;
      if (data.length === 1) {
        const firstItem = data[0];
        data = Array(2).fill(firstItem);
      }

      setSlider(data);
    }
  }, [props.data]);

  // autoplay banner with delay
  useEffect(() => {
    const autoplayInterval = setInterval(() => {
      if (ref.current && ref.current.goNext) {
        ref.current.goNext();
      }
    }, 3000);

    return () => {
      clearInterval(autoplayInterval);
    };
  }, []);

  // handle change banner (blur)
  const handleChange = useCallback((current) => {
    setCurrentIndex(current);
  }, []);

  const Card = React.memo(
    function (props) {
      const { data, dataIndex } = props;
      const cover = data[dataIndex];
      const isActive = dataIndex === currentIndex;
      return (
        <div className={`coverbannerImg ${isActive ? "active" : "inactive"}`}>
          <img className="bannerImg" draggable={false} src={cover} alt="" />
        </div>
      );
    },
    (prev, next) => prev.dataIndex === next.dataIndex
  );    

  return (
    <div className="my-4 pt-md-3">
      {slider.length === 0 ? (
        <div className="skeleton-loader-container">
          <div className="img-fluid rounded skeleton carouselCp" />
        </div>
      ) : (
        <ResponsiveContainer
          carouselRef={ref}
          render={(parentWidth, carouselRef) => {
            let slideWidth = 750;
            let currentVisibleSlide = 5;

            if (parentWidth <= 768) {
              slideWidth = parentWidth / 1;
              currentVisibleSlide = 3;
            } else if (parentWidth <= 1080) {
              currentVisibleSlide = 3;
            } else if (parentWidth <= 1440) {
              currentVisibleSlide = 3 ;
            }

            return (
              <>
                <StackedCarousel
                  ref={carouselRef}
                  data={slider}
                  carouselWidth={parentWidth}
                  slideWidth={slideWidth}
                  slideComponent={Card}
                  maxVisibleSlide={currentVisibleSlide}
                  currentVisibleSlide={currentVisibleSlide}
                  useGrabCursor={true}
                  onActiveSlideChange={handleChange}
                />
                <div className="carousel-dots">
                  {slider.map((_, index) => (
                    <button
                      key={index}
                      className={`dot ${currentIndex === index ? "active" : ""}`}
                      onClick={() => {
                        if (ref.current && ref.current.goTo) {
                          ref.current.goTo(index);
                        }
                      }}
                    />
                  ))}
                </div>
              </>
            );
          }}
        />
      )}
    </div>
  );
};

export default CarouselCp;
