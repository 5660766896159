import React from "react";
import { Card, Row, Col } from "react-bootstrap";
import "./GameCp.css";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const GameCp = (props) => {
  const settings = {
    infinite: false,
    slidesToShow: 6,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 2000,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          // dots: true,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          // dots: true,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
        },
      },
    ],
  };
  return (
    <>
      {props.data.length === 0 || undefined || null || "" ? (
        <Slider {...settings}>
          {[1, 2, 3, 4, 5, 6].map((index) => (
            <div key={index} className="  px-0 mx-0">
              <Card className="cardGame skeleton  py-0">
                <Card.Body className="text-center d-flex flex-column p-0">
                  <Card.Text className="color-subtitle fz-12 mb-0"></Card.Text>
                  <Card.Title className="title-product"></Card.Title>
                  <div className="link"></div>
                  <div className=" d-flex flex-column align-items-center ">
                    <span className="text-white text-center py-4 my-3"> </span>
                  </div>
                </Card.Body>
              </Card>
            </div>
          ))}
        </Slider>
      ) : (
        <Row className="mt-3 mb-2 px-2 px-sm-0 gy-3">
          {props.data
            .filter((val) => val.status)
            .map(
              (value, index) =>
                index < 6 && (
                  // {props.data
                  //   .filter((val) => val.status)
                  //   .map((value, index) => (
                  <Col key={index} xl={4} lg={4} md={6} sm={6} xs={12}>
                    <Link to={value.type === "manual" ? value.url : "/topup/" + value.code} className="link">
                      <Card className="cardGames">
                        <Card.Body className=" d-flex align-items-center p-1">
                          <Card.Img className="my-auto imgGames" variant="top" src={value.icon} />
                          <div className="d-block mx-1 ">
                            <Card.Title className="title-product  my-1 fw-semibold text-start ">{value.name}</Card.Title>
                            <Card.Title className="title-product  text-start ">{value.developer}</Card.Title>
                          </div>
                        </Card.Body>
                      </Card>
                    </Link>
                  </Col>
                )
            )}
        </Row>
      )}
    </>
  );
};

export default GameCp;
