import React, { useEffect, useState } from "react";
import "./FooterCp.css";
import { Container, Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";

const FooterCp = (props) => {
  const [logo, setLogo] = useState([]);
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [description, setDescription] = useState("");
  const [sosmed, setSosmed] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    if (props.data) {
      setLoading(false);
      setLogo(props.data.logo);
      setSosmed(props.data.contacts);
      setAddress(props.data.address);
      setEmail(props.data.email);
      setPhone(props.data.phone);
      setDescription(props.data.description);
    }
  }, [props]);
  return (
    <>
      <p className="text-dark mt-5 fw-400 text-center textDesc mx-3 ">{description}</p>
      <Navbar expand="lg" variant="light" className="footerCustom py-5 ">
        <Container className="px-3 px-sm-0 pt-5">
          <div className="d-flex flex-column w-100">
            <Navbar.Brand className="mb-4">
              <Link to="/">{loading ? <ThreeDots height="30" width="30" radius="9" color="#4fa94d" ariaLabel="three-dots-loading" wrapperStyle={{}} wrapperClassName="" visible={true} /> : <img src={logo} alt="" className="logo" />}</Link>
            </Navbar.Brand>
            {address && (
              <p className="text-dark fw-300 d-block ">
                <i className="bi bi-geo-alt me-2"></i>
                {address}
              </p>
            )}
              {phone && (
              <p className="text-dark fw-300 d-block ">
                <i className="bi bi-telephone me-2"></i>
                {phone}
              </p>
            )}
            {email && (
              <p className="text-dark fw-300 d-block ">
                <i className="bi bi-envelope me-2"></i>
                {email}
              </p>
            )}
          </div>
          <Nav className="d-flex flex-column my-2">
            <p className="fz-14 mb-1">Sosmed kami :</p>
            <div className="iconWrapper d-none d-lg-flex gap-0">
              {sosmed &&
                sosmed.map((item, index) => (
                  <Nav.Link key={index} href={item.url} className="text-dark" target="_blank">
                    <img src={`${item.icon}`} alt={`${item.name}`} width={22} />
                  </Nav.Link>
                ))}
            </div>
            <div className="d-flex d-lg-none gap-3">
              {sosmed &&
                sosmed.map((item, index) => (
                  <Nav.Link key={index} href={item.url} className="text-dark" target="_blank">
                    <img src={`${item.icon}`} alt={`${item.name}`} width={22} />
                  </Nav.Link>
                ))}
            </div>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};

export default FooterCp;
