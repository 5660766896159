import React, { useState } from "react";
import { Card, Row, Col, Button } from "react-bootstrap";
import "./GameCp.css";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Game2Cp = (props) => {
  const [visibleCount, setVisibleCount] = useState(12);

  const handleViewMore = () => {
    setVisibleCount((prevCount) => prevCount + 12); // Increase the count by 6 or any number you want
  };
  const settings = {
    infinite: false,
    slidesToShow: 6,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 2000,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
          infinite: true,
          // dots: true,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          // dots: true,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };
  return (
    <>
      {props.data.length === 0 || undefined || null || "" ? (
        <Slider {...settings}>
          {[1, 2, 3, 4, 5, 6].map((index) => (
            <div key={index} className=" my-4 px-0 mx-0">
              <Card className="cardGame2 skeleton w-100">
                <Card.Body className="text-center d-flex flex-column p-0">
                  <Card.Text className="color-subtitle fz-12 mb-0"></Card.Text>
                  <Card.Title className="title-product"></Card.Title>
                  <div className="link"></div>
                </Card.Body>
              </Card>
            </div>
          ))}
        </Slider>
      ) : (
        <Row className="mt-0 mb-5 px-2 px-sm-0 gy-3">
          {props.data
            .filter((val) => val.status)
            .map(
              (value, index) =>
                index < visibleCount && (
                  // {props.data
                  //   .filter((val) => val.status)
                  //   .map((value, index) => (
                  <Col key={index} xl={2} lg={3} md={4} sm={4} xs={4} className="px-2">
                    <Link to={value.type === "manual" ? value.url : "/topup/" + value.code} className="link">
                      {/* <Link className="link"> */}
                      <Card className="cardGames2 ">
                        <Card.Img className="imgGames2" variant="top" src={value.icon} />
                        <Card.Body className="detailGames2 text-center d-flex flex-column  px-md-3">
                          <Card.Text className="title-product fz-12 mb-2 text-dark">{value.developer}</Card.Text>
                          <Card.Title className="title-product  fw-semibold">{value.name}</Card.Title>
                        </Card.Body>
                      </Card>
                    </Link>
                  </Col>
                )
            )}
          {visibleCount < props.data.filter((val) => val.status).length && (
            <div className="text-center my-4">
              <Button className="btnGame px-5" onClick={handleViewMore}>
                View More...
              </Button>
            </div>
          )}
        </Row>
      )}
    </>
  );
};

export default Game2Cp;
