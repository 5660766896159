import React, { useEffect, useState } from "react";
import NavbarCp from "../../components/NavbarCp";
import notTrx from "../../assets/img/belum_trx.png";
import FooterCp from "../../components/FooterCp";
import ChatUs from "../../partials/chatUs";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { listTrx } from "../../services";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { Helmet, HelmetProvider } from "react-helmet-async";
import swal from "sweetalert";
import moment from "moment";
const inputSubject = new Subject();

const CheckTrx = () => {
  const [phone, setPhone] = useState("");
  const [site, setSite] = useState([]);
  const [title, setTitle] = useState("");
  const [state, setState] = useState({
    query: "",
    list: [],
  });

  useEffect(() => {
    const subscription = inputSubject.pipe(debounceTime(1000)).subscribe(async (value) => {
      if (value.length >= 11) {
        const results = await listTrx(value);
        setState({ query: value, list: results });
      }
    });

    return () => subscription.unsubscribe();
  }, []);
  const detailSite = async () => {
    await fetch(`${process.env.REACT_APP_BASE_URL}/data/setting`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + process.env.REACT_APP_TOKEN,
      },
    })
      .then((r) => r.json().then((data) => ({ status: r.status, data: data.data })))
      .then((data) => {
        setSite(data.data[0]);
        setTitle(data.data[0].title);
      })
      .catch((err) => {
        swal(err.message, {
          icon: "error",
          button: true,
        });
      });
  };

  const handleWa = (e) => {
    const { value } = e.target;
    if (value.startsWith("0")) {
      setPhone("62" + value.substring(1));
    } else {
      setPhone(value);
    }
    inputSubject.next(value.startsWith("0") ? "62" + value.substring(1) : value);
  };

  // subject
  //   .asObservable()
  //   .pipe(debounceTime(500))
  //   .subscribe(async (e) => {
  //     const results = await listTrx(e);
  //     setState({
  //       query: e,
  //       list: results,
  //     });
  //   });

  // const handleKeyup = (e) => {
  //   subject.next(e.target.value);
  // };

  useEffect(() => {
    window.scrollTo(0, 0);
    setPhone("");
    detailSite();
  }, []);

  return (
    <HelmetProvider>
      <Helmet prioritizeSeoTags>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta content="yes" name="apple-mobile-web-app-capable" />
        <meta name="viewport" content="width=device-width, minimum-scale=1, maximum-scale=1, initial-scale=1, user-scalable=no" />
        <title>{title} - Website topup termurah dan terbaik</title>
        <meta name="application-name" content={title} />
        <meta name="copyright" content={`© 2023 by ${title}`} />
        <meta name="description" content={`Top up or buy game credits/diamonds/game voucher, fast and cheap at ${title}. ${title} is the best way to buy game credits/game vouchers.`} />
        <meta name="title" content={`${title} - Website topup termurah dan terbaik`} />
        <meta name="robots" content="noodp, noydir" />
        <meta name="theme-color" content="#ff962d" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`${title} ${title} - Cheapest and Fastest Online Game Voucher`} />
        <meta property="og:description" content={`Top up or buy game credits/diamonds/game voucher, fast and cheap at ${title}. ${title} is the best way to buy game credits/game vouchers.`} />
        <meta property="twitter:card" content="summary" />
        <meta property="twitter:site" content={`@${title.toLowerCase()}`} />
        <meta property="twitter:title" content={`${title} ${title} - Cheapest and Fastest Online Game Voucher`} />
        <meta property="twitter:description" content={`Top up or buy game credits/diamonds/game voucher, fast and cheap at ${title}. ${title} is the best way to buy game credits/game vouchers.`} />
      </Helmet>
      <div className="set-height">
        <NavbarCp data={site} />
        <Container>
          <Row className="mt-3 mb-5">
            <Col xs={12}>
              <div className="style-area text-dark py-3">
                <h6>Cek Transaksi</h6>
                <Form.Control name="wa" className="my-2" type="number" placeholder="62XXXXXXXXXXX" onChange={handleWa} value={phone || ""} />
                <span className="text-dark d-block">*Masukkan nomor whatsapp yang kamu gunakan ketika melakukan transaksi</span>
              </div>
            </Col>
          </Row>
          <Row className="my-auto">
            {state.query === "" ? (
              <div className="d-flex flex-column align-items-center">
                <img src={notTrx} alt="Not Found" className="w-50" />
                <h3 className="text-dark text-center ">Belum Ada Transakasi</h3>
                <span className="text-dark text-center ">Riwayat transaksi kamu akan muncul di sini. Mulai top up game favorit kamu sekarang.</span>
              </div>
            ) : state.list.length === 0 ? (
              <div className="d-flex flex-column align-items-center">
                <img src={notTrx} alt="Not Found" className="w-50" />
                <h3 className="text-dark text-center">Data tidak ditemukan</h3>
                <span className="text-dark text-center ">Riwayat transaksi kamu akan muncul di sini. Mulai top up game favorit kamu sekarang.</span>
              </div>
            ) : (
              state.list.map((item, index) => (
                <Col md={6} className="mb-3" key={index}>
                  {/* <div className="style-area card-history flex-fill text-dark py-3"> */}
                  <div className="style-area text-dark py-3">
                    <h6 className="mb-4">Detail Transaksi</h6>
                    <Row className="my-2 ">
                      <Col xs={4}>
                        <span>Ref Transaksi</span>
                      </Col>
                      <Col xs={1}>
                        <span>:</span>
                      </Col>
                      <Col xs={7}>
                        <span>{item.ref}</span>
                      </Col>
                    </Row>
                    <Row className="my-2 ">
                      <Col xs={4}>
                        <span>Username</span>
                      </Col>
                      <Col xs={1}>
                        <span>:</span>
                      </Col>
                      <Col xs={7}>
                        <span>{(item.customer.username ? `${item.customer.username}` : "-")}</span>
                      </Col>
                    </Row>
                    <Row className="my-2 ">
                      <Col xs={4}>
                        <span>ID Game</span>
                      </Col>
                      <Col xs={1}>
                        <span>:</span>
                      </Col>
                      <Col xs={7}>
                        <span>{item.customer.id }</span>
                      </Col>
                    </Row>
                    {item.customer.server ? 
                    <Row className="my-2 ">
                      <Col xs={4}>
                        <span>Server</span>
                      </Col>
                      <Col xs={1}>
                        <span>:</span>
                      </Col>
                      <Col xs={7}>
                        <span>{item.customer.server ? item.customer.server : "-"}</span>
                      </Col>
                    </Row>
                    : ""}
                    <Row className="my-2 ">
                      <Col xs={4}>
                        <span>Item</span>
                      </Col>
                      <Col xs={1}>
                        <span>:</span>
                      </Col>
                      <Col xs={7}>
                        <span>{item.product.name}</span>
                      </Col>
                    </Row>
                    <Row className="my-2 ">
                      <Col xs={4}>
                        <span>Product</span>
                      </Col>
                      <Col xs={1}>
                        <span>:</span>
                      </Col>
                      <Col xs={7}>
                        <span>{item.product.game.name}</span>
                      </Col>
                    </Row>
                    <Row className="my-2 ">
                      <Col xs={4}>
                        <span>Metode Pembayaran</span>
                      </Col>
                      <Col xs={1}>
                        <span>:</span>
                      </Col>
                      <Col xs={7}>
                        <span>{item.payment.name}</span>
                      </Col>
                    </Row>
                    <Row className="my-2 ">
                      <Col xs={4}>
                        <span>Jumlah</span>
                      </Col>
                      <Col xs={1}>
                        <span>:</span>
                      </Col>
                      <Col xs={7}>
                        <span>{item.total_payment}</span>
                      </Col>
                    </Row>
                    <Row className="my-2 ">
                      <Col xs={4}>
                        <span>Tanggal Order</span>
                      </Col>
                      <Col xs={1}>
                        <span>:</span>
                      </Col>
                      <Col xs={7}>
                        <span>{moment(item.createdAt).format("DD MMMM YYYY, HH:mm:ss")}</span>
                      </Col>
                    </Row>
                    <Row className="my-2 ">
                      <Col xs={4}>
                        <span>Status</span>
                      </Col>
                      <Col xs={1}>
                        <span>:</span>
                      </Col>
                      <Col xs={7}>
                        <span>{item.status}</span>
                      </Col>
                      {item.status === "PENDING" ? (
                        <Button className="mt-3 outline-purple" variant="" href={item.checkout_url}>
                          Lanjutkan Pembayaran
                        </Button>
                      ) : (
                        ""
                      )}
                    </Row>
                  </div>
                </Col>
              ))
            )}
          </Row>
        </Container>
        <ChatUs data={site} />
        <FooterCp data={site} />
      </div>
    </HelmetProvider>
  );
};

export default CheckTrx;
