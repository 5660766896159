import React, { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Col, Form, Accordion, Button, Toast, Row } from "react-bootstrap";
import "./FormTopup.css";
import { BsFillCheckCircleFill, BsFillExclamationOctagonFill } from "react-icons/bs";
import { ThreeDots } from "react-loader-spinner";
import ReactRecaptcha3 from "react-google-recaptcha3";
import { useLocation } from "react-router-dom";
import ModalCp from "../ModalCp";
import authorizeToken from "../../services/token";
import swal from "sweetalert";
// import axios from "axios";

const FormTopup = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const formRefs = useRef([]);
  const location = useLocation();
  const ign = useRef(null || "");
  const wa = useRef(null || "");
  const nameproduct = useRef(null || "");
  const paymentMethodRef = useRef(null || "");
  const margin = useRef(null || "");
  const captchaRef = useRef(null);
  const paymentRef = useRef([] || null);

  const [usernm, setUsernm] = useState("");
  const [formState, setFormState] = useState({});
  const [gameCode, setGameCode] = useState("");
  const [productCode, setProductCode] = useState("");
  const [productName, setProductName] = useState("");
  const [totalPaymentState, setTotalPaymentState] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [phone, setPhone] = useState("");
  const [paymentId, setPaymentId] = useState("");
  const [paymentState, setPaymentState] = useState([]);
  const [paymentUsed, setPaymentUsed] = useState(null);
  const [paymentImage, setPaymentImage] = useState("");
  const [tempPrice, setTemprice] = useState(0);

  const [loading, setLoading] = useState(false);
  const [loadingCheckAkun, setLoadingCheckAkun] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);

  const [toast, setToast] = useState(false);
  let [toastMsg, setToastMsg] = useState([]);

  const [verified, setVerified] = useState(false);
  const [cover, setCover] = useState("");
  const [banner, setBanner] = useState("");
  const [desc, setDesc] = useState("");
  const [form, setForm] = useState([]);
  const [name, setName] = useState("");
  const [dev, setDev] = useState("");
  const [product, setProduct] = useState([]);
  const [paymentM, setPaymentM] = useState([]);
  const [idGame, setIdGame] = useState("");
  const [selectedRadio, setSelectedRadio] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");


  // voucher
  const [voucher, setVoucher] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [discountType, setDiscountType] = useState(null);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [maxDiscount, setMaxDiscount] = useState(0);
  const [minDiscountTrx, setMinDiscountTrx] = useState(0);

  // sticky display none before add item
  const [display, setDisplay] = useState({
    detail: "none",
  });
  const [searchParams] = useSearchParams();
  const selectProd = searchParams.get("selected");

  useEffect(() => {
    if (selectProd) {
      setSelectedRadio(selectProd);

      function clickIt() {
        const doc = document.getElementById(`trigger-${selectProd}`);
        if (doc) {
          doc.click();
          if (formRefs.current[0]) {
            formRefs.current[0].focus();
          }
        } else {
          setTimeout(clickIt, 100);
        }
      }

      clickIt();
    }
  }, [selectProd]);

  const handleRadioChange = (event) => {
    setSelectedRadio(event.target.value);
    if (formRefs.current[0]) {
      formRefs.current[0].focus();
    }
  };

  //captcha handler
  function handleCaptcha() {
    setVerified(true);
  }

  // get product,payment form BE
  async function formGame() {
    setLoading(true);
    const urlParams = window.location.pathname.split("/").pop();
    await fetch(`${process.env.REACT_APP_BASE_URL}/data/product?game=${urlParams}`, { method: "GET", ...authorizeToken })
      .then((r) => r.json().then((data) => ({ status: r.status, data: data.data })))
      .then((data) => {
        if (data) {
          setCover(data.data.cover);
          setBanner(data.data.icon);
          setForm(data.data.form.fields);
          setName(data.data.name);
          setDev(data.data.developer);
          setDesc(data.data.description);
          setProduct(data.data.product);
          setIdGame(data.data.form.check);
        } else {
          setCover("");
          setBanner([]);
          setForm([]);
          setName([]);
          setDev([]);
          setDesc([]);
          setProduct([]);
          setIdGame("");
        }
      })
      .catch((err) => {
        swal(err.message, {
          icon: "error",
          button: true,
        });
      });
    await fetch(`${process.env.REACT_APP_BASE_URL}/data/payment`, {
      method: "GET",
      ...authorizeToken,
    })
      .then((r) => r.json().then((data) => ({ status: r.status, data: data.data })))
      .then((data) => {
        if (data) {
          setPaymentM(data.data);
          setPaymentState(data.data);
        } else {
          setPaymentM([]);
          setPaymentState([]);
        }
      })
      .catch((err) => {
        swal(err.message, {
          icon: "error",
          button: true,
        });
      });

    setGameCode(urlParams);
    setLoading(false);
  }

  // format rupiah
  function formatRP(margin) {
    const formatted = new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
      minimumFractionDigits: 2,
    }).format(Math.round(margin));

    return formatted.replace(",00", "");
  }

  function totalPayment(margin) {
    paymentState.forEach((item) => {
      item.methods.forEach((item2) => {
        const harga = parseFloat(margin) || tempPrice;
        const feeFlat = item2.flat;
        const feePercent = (parseFloat(harga) * parseFloat(item2.percent)) / 100;

        let amount = Math.ceil(harga) + Math.ceil(feeFlat) + Math.ceil(feePercent);

        const refKey = `${item2._id}`;
        // remove format
        let currentValue = parseFloat(
          paymentRef.current[refKey].innerHTML
            .replace(/&nbsp;/g, " ")
            .replace(/Rp\s?/g, "")
            .replace(/\./g, "")
        );
        if (discountAmount && currentValue >= minDiscountTrx) {
          let discount = 0;
          discount = discountType === "percent" ? (amount * discountAmount) / 100 : discountAmount;
          discount = Math.min(discount, maxDiscount);
          amount = amount - discount;
        } else if (discountAmount && currentValue < minDiscountTrx) {
          setResponseMessage("Minimal pembelian " + formatRP(minDiscountTrx));
        } else {
          setResponseMessage("");
        }

        paymentRef.current[refKey].innerHTML = formatRP(amount);
        setTotalPaymentState(amount);
      });
    });
  }

  // select diaomond
  const diamondSelect = (nameproduct, code, margin, discountedPrice) => () => {
    const selectedMargin = Number(discountedPrice) || Number(margin); // Use discounted price if available, otherwise use margin

    let a = document.getElementsByClassName("nameproduct");
    for (let i = 0; i < a.length; i++) {
      setProductName(nameproduct);
    }

    let b = document.getElementsByClassName("code");
    for (let i = 0; i < b.length; i++) {
      b[i].value = code;
    }

    let c = document.getElementsByClassName("margin");
    for (let i = 0; i < c.length; i++) {
      c[i].innerHTML = formatRP(selectedMargin);
      c[i].value = selectedMargin;
    }

    setProductCode(code);
    setTemprice(selectedMargin);
    totalPayment(selectedMargin);
  };

  // select payment
  const paySelect = (payment, paymentId, paymentImage) => () => {
    let a = document.getElementsByClassName("payment");
    let b = document.getElementsByClassName("paymentId");
    for (let i = 0; i < a.length; i++) {
      a[i].value = payment.toUpperCase();
    }
    for (let i = 0; i < b.length; i++) {
      b[i].value = paymentId.toUpperCase();
    }
    setDisplay({ ...display, detail: "block" });
    setPaymentMethod(payment.toUpperCase());
    setPaymentId(paymentId);
    setPaymentImage(paymentImage);
    setPaymentUsed(payment.toUpperCase());
  };

  // handler input number
  const handleInputNumber = (e) => {
    const allowedKeys = ["Backspace", "Delete", "Tab", "Escape", "Enter", "ArrowLeft", "ArrowRight"];
  
    if (allowedKeys.includes(e.key)) return;
  
    if (!/^\d$/.test(e.key)) {
      e.preventDefault();
    }
  };

  // handler whatsapp number (0 auto 62)
  const handleWa = (e) => {
    const { value } = e.target;
    if (value.length < 15) {
      if (value.startsWith("0")) {
        setPhone("62" + value.substring(1));
      } else {
        setPhone(value);
      }
    }
  };

  useEffect(() => {
    ReactRecaptcha3.init(process.env.REACT_APP_SITE_KEY);
    setBanner("");
    setForm([]);
    setFormState({});
    setUsernm("");
    setName("");
    setDev("");
    setDesc("");
    setProduct([]);
    setPaymentM([]);
    setIdGame("");
    setPaymentState([]);
    setProductName("");
    setDisplay({
      detail: "none",
    });
    setPhone("");
    formGame();
  }, [location]);

  useEffect(() => {
    totalPayment(margin);
  }, [discountAmount, discountType, margin, paymentState, selectedRadio]);

  // validation form
  const handleValidation = (e) => {
    e.preventDefault();
    toastMsg = [];
    setToastMsg([]);
    if (Object.keys(formState).length !== form.length + 2) {
      setToast(true);
      toastMsg = toastMsg.concat(["Form tujuan tidak boleh kosong"]);
      setToastMsg(toastMsg);
    }

    if (usernm === null) {
      setToast(true);
      toastMsg = toastMsg.concat(["Akun tidak ditemukan"]);
      setToastMsg(toastMsg);
    }

    if (productCode === "") {
      setToast(true);
      toastMsg = toastMsg.concat(["Form produk tidak boleh kosong"]);
      setToastMsg(toastMsg);
    }

    if (paymentMethod === "") {
      setToast(true);
      toastMsg = toastMsg.concat(["Form Metode Pembayaran tidak boleh kosong"]);
      setToastMsg(toastMsg);
    }

    if (phone === "") {
      setToast(true);
      toastMsg = toastMsg.concat(["Form whatsapp tidak boleh kosong"]);
      setToastMsg(toastMsg);
    } else if (phone.length < 9 || phone.length > 14) {
      setToast(true);
      toastMsg = toastMsg.concat(["Form whatsapp tidak valid"]);
      setToastMsg(toastMsg);
    }

    if (toastMsg.length === 0) {
      handleShow();
    }
  };

  // handler submit transaction
  const handleSubmit = (e) => {
    e.preventDefault();
    ReactRecaptcha3.getToken().then((resp) => {
      setLoadingModal(true);
      setShow(false);
      let url = new URLSearchParams(window.location.search);
      let ref = url.get("r");
      fetch(`${process.env.REACT_APP_BASE_URL}/data/trx/`, {
        method: "POST",
        mode: "cors",
        body: JSON.stringify({
          formState,
          gameCode,
          productCode,
          totalPayment: totalPaymentState,
          paymentMethod,
          paymentId,
          voucherCode: voucher,
          phone,
          ign: usernm,
          token: resp,
          referral: ref || null,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + process.env.REACT_APP_TOKEN,
        },
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          if (data.url) {
            window.location.href = data.url;
          } else {
            setLoadingModal(false);
            setShow(false);
            setToast(true);
            setToastMsg(data.msg);
          }
        })
        .catch((err) => {
          swal(err.message, {
            icon: "error",
            button: true,
          });
        });
    });
  };

  // gruping product
  const groupedProducts = {};
  product.forEach((item) => {
    if (!groupedProducts[item.category]) {
      groupedProducts[item.category] = [];
    }
    groupedProducts[item.category].push(item);
  });

  return (
    <>
      {loading ? (
        <div className="d-flex justify-content-center">
          <ThreeDots height="60" width="60" radius="9" color="#4fa94d" ariaLabel="three-dots-loading" wrapperStyle={{}} visible={true} />
        </div>
      ) : (
        <img className="cover mx-auto rounded-4 my-2 my-md-4" src={cover} alt="Banner Topup" />
      )}
      <Row>
        <Col lg={4} className=" text-light mb-4">
          {loading ? (
            <div className="d-flex justify-content-center">
              <ThreeDots height="60" width="60" radius="9" color="#4fa94d" ariaLabel="three-dots-loading" wrapperStyle={{}} visible={true} />
            </div>
          ) : (
            <div className="d-flex flex-column gap-3 bgForm p-2">
              <div className="d-flex align-items-center">
                <img className="banner d-block" src={banner} alt="Sub Banner Topup" />
                <div className="d-block mx-2 ">
                  <h5 className="mt-lg-2 m-0">{name}</h5>
                  <h6 className="mt-lg-2 fw-normal">{dev}</h6>
                </div>
              </div>
              <p className="fw-300 fz-14" dangerouslySetInnerHTML={{ __html: desc }}></p>
            </div>
          )}
        </Col>
        <Col lg={8} className="text-dark">
          <div className="toastError">
            {toast
              ? toastMsg.map((item, index) => (
                  <Toast className="w-auto my-2" key={index} bg="danger" onClose={() => setToast(false)} show={toast} delay={6000} autohide>
                    <div className="d-flex align-items-center justify-content-start ps-2">
                      <BsFillExclamationOctagonFill className="fs-4" />
                      <Toast.Body>{item}</Toast.Body>
                    </div>
                  </Toast>
                ))
              : ""}
          </div>
          <Form className="w-100 m-auto" onSubmit={handleValidation}>
            <div className="style-area mb-4">
              <ul className="list-style-none p-0 m-0 d-flex align-items-center gap-3">
                <li className="list-number px-2 py-2">1</li>
                <li className="fs-5 mt-2">Pilih Item</li>
              </ul>
              {loading ? (
                <div className="d-flex justify-content-center">
                  <ThreeDots height="30" width="30" radius="9" color="#4fa94d" ariaLabel="three-dots-loading" wrapperStyle={{}} visible={true} />
                </div>
              ) : (
                <div className="mt-3 ">
                  <input 
                    type="text" 
                    placeholder="Search products..." 
                    className="form-control mb-3 searchProduct" 
                    value={searchQuery} 
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                  {Object.keys(groupedProducts).map((category, index) => (
                    groupedProducts[category].filter((val) => val.status && val.name.toLowerCase().includes(searchQuery.toLowerCase())).length > 0 ? (
                      <div key={index}>
                        <h6>{category !== "undefined" ? category : "Top Up Instant"}</h6>
                        <div className="row ">
                        {groupedProducts[category]
                        .filter((val) => val.status && val.name.toLowerCase().includes(searchQuery.toLowerCase()))
                          // short by margin
                          .sort((a, b) => a.margin - b.margin)
                          // short by name
                          // .sort((a, b) => {
                          //   let nameA = a.name.toUpperCase();
                          //   let nameB = b.name.toUpperCase();
                          //   return nameA.localeCompare(nameB, undefined, {
                          //     numeric: true,
                          //     sensitivity: "base",
                          //   });    
                          // })
                          .map((item, index) => (
                            <div className="col-6 col-sm-6 col-md-4 d-flex mb-1 mx-0 px-0" key={index}>
                              <label className={`card-topup mb-1 flex-fill  ${selectedRadio === item.code ? "selected" : ""}`} key={index}>
                                <input type="radio" name="diamond" className="radio-custom" value={productCode || ""} checked={selectedRadio === item.code} onChange={handleRadioChange} />
                                <div
                                  className={`radio-btn d-flex justify-content-between align-items-center px-2 py-md-3`}
                                  id={`trigger-${item.code}`}
                                  onClick={diamondSelect(`${item.name}`, `${item.code}`, `${item.margin}`, `${item.discountedPrice}`)}
                                >
                                  {/* <BsFillCheckCircleFill className="check-custom float-end" /> */}
                                  <div className="text-container d-flex flex-column">
                                    <span className="fz-13">{item.name}</span>
                                    <span className="fz-11 activeText">
                                      {item.discountedPrice ? (
                                        <>
                                          <span className="original-price">{formatRP(item.margin)}</span> {formatRP(item.discountedPrice)}
                                        </>
                                      ) : (
                                        <span className="fz-11 activeText">{formatRP(item.margin)}</span>
                                      )}
                                    </span>
                                  </div>
                                  <img src={item.icon} alt="Logo" className="iconItem " />
                                </div>
                              </label>
                            </div>
                          ))}
                      </div>
                      </div>
                    ) : ''   
                  ))}
                </div>
              )}
            </div>
            <div className="style-area mb-4 ">
              <ul className="list-style-none p-0 m-0 d-flex align-items-center gap-3">
                <li className="list-number px-2 py-2">2</li>
                <li className="fs-5  ">Masukkan Data Akun</li>
              </ul>
              {loading ? (
                <div className="d-flex justify-content-center">
                  <ThreeDots height="30" width="30" radius="9" color="#4fa94d" ariaLabel="three-dots-loading" wrapperStyle={{}} visible={true} />
                </div>
              ) : (
                <div className="mt-3">
                  {form ? (
                    <Row>
                      {form.map((item, index) => (
                        <Col xs={12} md={6} className="mb-3" key={index}>
                          {item.type === "number" || item.type === "text" || item.type === "email" || item.type === "password" ? (
                            <Form.Control
                              className="input-custom"
                              ref={(ref) => {
                                formRefs.current[index] = ref;
                              }}
                              name={item.name}
                              type={item.type}
                              placeholder={item.placeholder}
                              onKeyDown={handleInputNumber}
                              onChange={(e) => {
                                setFormState((fs) => ({
                                  ...fs,
                                  [item.name]: e.target.value,
                                  game: idGame,
                                  length: form.length,
                                }));
                              }}
                              value={formState[item.name] || ""}
                              required
                              onWheel={(e) => e.target.blur()} 
                              onBlur={(e) => {
                                if (idGame) {
                                  let finalLength = form.length + 2;
                                  if (Object.keys(formState).length === finalLength) {
                                    setLoadingCheckAkun(true);
                                    ReactRecaptcha3.getToken().then((resp) => {
                                      fetch(`${process.env.REACT_APP_BASE_URL}/data/game/check`, {
                                        method: "POST",
                                        mode: "cors",
                                        body: JSON.stringify({
                                          formState: formState,
                                          token: resp,
                                        }),
                                        headers: {
                                          "Content-Type": "application/json",
                                          Authorization: "Bearer " + process.env.REACT_APP_TOKEN,
                                        },
                                      })
                                        .then((r) =>
                                          r.json().then((data) => ({
                                            data: data,
                                          }))
                                        )
                                        .then((data) => {
                                          setUsernm(data.data.username);
                                          ign.current.value = data.data.username;
                                          setLoadingCheckAkun(false);
                                        })
                                        .catch((err) => {
                                          swal(err.message || "Data Not Found", {
                                            icon: "error",
                                            button: true,
                                          });
                                        });
                                    });
                                  }
                                }
                              }}
                            />
                          ) : item.type === "select" ? (
                            <div className="select">
                              <Form.Select
                                ref={(ref) => {
                                  formRefs.current[index] = ref;
                                }}
                                name={item.name}
                                type={item.type}
                                placeholder={item.placeholder}
                                onChange={(e) => {
                                  setFormState((fs) => ({
                                    ...fs,
                                    [item.name]: e.target.value,
                                    game: idGame,
                                    length: form.length,
                                  }));
                                }}
                                value={formState[item.name] || ""}
                                onBlur={(e) => {
                                  if (idGame) {
                                    let finalLength = form.length + 2;
                                    if (Object.keys(formState).length === finalLength) {
                                      ReactRecaptcha3.getToken().then((resp) => {
                                        fetch(`${process.env.REACT_APP_BASE_URL}/data/game/check`, {
                                          method: "POST",
                                          mode: "cors",
                                          body: JSON.stringify({
                                            formState: formState,
                                            token: resp,
                                          }),
                                          headers: {
                                            "Content-Type": "application/json",
                                            Authorization: "Bearer " + process.env.REACT_APP_TOKEN,
                                          },
                                        })
                                          .then((r) =>
                                            r.json().then((data) => ({
                                              data: data,
                                            }))
                                          )
                                          .then((data) => {
                                            setUsernm(data.data.username);
                                            ign.current.value = data.data.username;
                                            setLoadingCheckAkun(false);
                                          })
                                          .catch((err) => {
                                            swal(err.message, {
                                              icon: "error",
                                              button: true,
                                            });
                                          });
                                      });
                                    }
                                  }
                                }}
                              >
                                <option value="">{item.placeholder}</option>
                                {item.options.map((opt, index) => (
                                  <option key={index} value={opt.value}>
                                    {opt.text}
                                  </option>
                                ))}
                              </Form.Select>
                            </div>
                          ) : (
                            ""
                          )}
                        </Col>
                      ))}
                    </Row>
                  ) : (
                    ""
                  )}
                </div>
              )}

              <div className="bg-form d-flex justify-content-between">
                <span className={`text-${usernm !== null ? "light" : "danger"}`}>
                  {usernm === "" && !loadingCheckAkun ? (
                    "Nickname(otomatis)"
                  ) : loadingCheckAkun ? (
                    <ThreeDots height="30" width="30" radius="9" color="#4fa94d" ariaLabel="three-dots-loading" wrapperStyle={{}} wrapperClassName="" visible={true} />
                  ) : (
                    `${usernm !== null ? usernm : "Akun tidak ditemukan"}`
                  )}
                </span>
              </div>
            </div>

            <input type="hidden" name="gameCode" onChange={(e) => setGameCode(e.target.value)} value={gameCode || ""} />
            <input className="code" ref={nameproduct} type="hidden" name="productCode" onChange={(e) => setProductCode(e.target.value)} value={productCode || ""} />
            <div className="style-area mb-4">
              <ul className="list-style-none p-0 m-0 d-flex align-items-center gap-3">
                <li className="list-number px-2 py-2">3</li>
                <li className="fs-5">Metode Pembayaran</li>
              </ul>
              {loading ? (
                <div className="d-flex justify-content-center">
                  <ThreeDots height="30" width="30" radius="9" color="#4fa94d" ariaLabel="three-dots-loading" wrapperStyle={{}} visible={true} />
                </div>
              ) : (
                <Accordion defaultActiveKey="0" className="mt-3">
                  {paymentM.map((item, index) => (
                    <Accordion.Item eventKey={index} key={index} className="mt-1">
                      <Accordion.Header>
                        <span>{item._id}</span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="mt-3 row">
                          {item.methods
                            .filter((val) => val.status)
                            .map((item2, index) => (
                              <label className="col-12 col-md-6  card-payment mb-4" key={index}>
                                <input type="radio" name="radio2" className="radio-custom" value={paymentMethod || ""} />
                                <div className="radio-btn2 d-flex justify-content-between px-3 py-4" onClick={paySelect(`${item2.name.toLowerCase()}`, `${item2._id}`, `${item2.image}`)}>
                                  <BsFillCheckCircleFill className="check-custom float-end" />
                                  <div className="wrapper-logo">
                                    <img className="payment-logo" src={item2.image} alt="Logo" />
                                  </div>
                                  <span
                                    className="margin me-3 text-dark"
                                    ref={(ref, ref2) => {
                                      paymentRef.current[item2._id] = ref;
                                      // paymentRef.current[item2.name] = ref2;
                                    }}
                                  ></span>
                                </div>
                              </label>
                            ))}
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
                </Accordion>
              )}
            </div>

            <div className="style-area mb-4">
              <ul className="list-style-none p-0 m-0 d-flex align-items-center gap-3">
                <li className="list-number px-2 py-2">4</li>
                <li className="fs-5">Kode Promo</li>
              </ul>
              <div className="mt-3 ">
                <Form.Control
                  className="input-custom"
                  name="voucher"
                  type="text"
                  placeholder="Ketik Kode Promo Anda"
                  onChange={(e) => setVoucher(e.target.value)}
                  value={voucher}
                  onBlur={(e) => {
                    e.preventDefault();
                    setLoadingCheckAkun(true);
                    ReactRecaptcha3.getToken().then((resp) => {
                      fetch(`${process.env.REACT_APP_BASE_URL}/data/redeem`, {
                        method: "POST",
                        mode: "cors",
                        body: JSON.stringify({
                          voucherCode: voucher,
                        }),
                        headers: {
                          "Content-Type": "application/json",
                          Authorization: "Bearer " + process.env.REACT_APP_TOKEN,
                        },
                      })
                        .then((r) =>
                          r.json().then((data) => ({
                            data: data,
                          }))
                        )
                        .then((data) => {
                          let responseMessage = "Connecting...";
                          if (data.data.type === "percent" || data.data.type === "flat") {
                            responseMessage = data.data.type === "percent" ? `Diskon ${data.data.discount}% (Max.${formatRP(data.data.maxDiscount)})` : `Diskon : ${formatRP(data.data.discount)}`;

                            setDiscountType(data.data.type);
                            setDiscountAmount(data.data.discount);
                            setMaxDiscount(data.data.maxDiscount);
                            setMinDiscountTrx(data.data.minTrx);
                          } else {
                            responseMessage = data.data.msg;
                            setDiscountType(null);
                            setDiscountAmount(0);
                            setMaxDiscount(0);
                            setMinDiscountTrx(0);
                            // resetPrices(); // Reset prices
                          }
                          setResponseMessage(responseMessage);
                          setLoadingCheckAkun(false);
                        })

                        .catch((err) => {
                          swal(err.message, {
                            icon: "error",
                            button: true,
                          });
                          setLoadingCheckAkun(false);
                          setResponseMessage("Error checking voucher");
                          setDiscountType(null);
                          setDiscountAmount(0);
                          setMaxDiscount(0);
                          setMinDiscountTrx(0);
                        });
                    });
                  }}
                />
                <div className="d-flex justify-content-between">
                  {responseMessage && <span className=" text-end fz-14 ">{responseMessage}</span>}
                  {loadingCheckAkun && <p className="m-0">Loading...</p>}
                </div>
              </div>
            </div>

            <input className="payment" ref={paymentMethodRef} type="hidden" name="paymentMethod" onChange={(e) => setPaymentMethod(e.target.value)} value={paymentMethod || ""} />
            <input type="hidden" name="paymentId" value={paymentId || ""} />
            <div className="style-area mb-4">
              <ul className="list-style-none p-0 m-0 d-flex align-items-center gap-3">
                <li className="list-number px-2 py-2">5</li>
                <li className="fs-5 mt-2">Whatsapp</li>
              </ul>
              <div className="mt-3 gap-3">
                <Form.Control ref={wa} name="wa" className="w-100" type="number" placeholder="62XXXXXXXXXXX" onKeyDown={handleInputNumber} onChange={handleWa} value={phone || ""} onWheel={(e) => e.target.blur()} required />
                <span className="text-dark fz-12">* Format Nomor yang digunakan adalah 62XXXXXXX</span>
              </div>
            </div>
            <input ref={margin} type="hidden" name="totalPayment" onChange={(e) => setTotalPaymentState(e.target.value)} value={totalPaymentState || ""} />
            <input type="hidden" name="ign" ref={ign} value={usernm || ""} />
            <div className="total style-area-sticky" style={{ display: display.detail }}>
              <div className="my-2">
                <div className=" d-flex align-items-center py-3">
                  <img className="bannerDetail d-none d-lg-block" src={banner} alt="Sub Banner Topup" />
                  <div className="d-flex flex-column mx-2">
                    <p className="my-0 nameproduct">{productName}</p>
                    <h6 className="my-0 fw-500">
                      <span className="text-purple ">
                        {paymentId != null && paymentRef.current[paymentId] ? paymentRef.current[paymentId].innerHTML.replace(/&nbsp;/g, " ") : ""} -
                        <img className="" height={16} src={paymentImage} alt="Payment Method" />
                      </span>
                    </h6>
                  </div>
                </div>
                <Button className="btn btn-purple w-100 d-flex justify-content-center m-auto px-4 mb-3" variant="primary" type="submit">
                  Beli Sekarang
                </Button>
              </div>

              <ModalCp
                show={show}
                handleClose={handleClose}
                usernm={usernm}
                form={form}
                formState={formState}
                wa={wa.current.value}
                nameproduct={nameproduct.current.value}
                payment={paymentMethodRef.current.value}
                paymentused={paymentUsed}
                paymentref={paymentRef}
                captchaRef={captchaRef}
                handleCaptcha={handleCaptcha}
                verified={verified}
                loadingModal={loadingModal}
                handleSubmit={handleSubmit}
                price={paymentId != null && paymentRef.current[paymentId] ? paymentRef.current[paymentId].innerHTML.replace(/&nbsp;/g, " ") : ""}
              />
            </div>
          </Form>
        </Col>
        {loadingModal ? (
          <div className="overlay">
            <span className="custom-loader">
              <ThreeDots height="150" width="150" radius="9" color="#4fa94d" ariaLabel="three-dots-loading" visible={true} />
            </span>
          </div>
        ) : (
          ""
        )}
      </Row>
    </>
  );
};

export default FormTopup;
