import React, { useEffect, useState } from "react";
import NavbarCp from "../../components/NavbarCp";
import FooterCp from "../../components/FooterCp";
import notFound from "../../assets/img/notFound.png";
import { Container } from "react-bootstrap";
import { Helmet, HelmetProvider } from "react-helmet-async";
import swal from "sweetalert";

const Notfound = () => {
  const [site, setSite] = useState([]);
  const [title, setTitle] = useState("");

  const detailSite = async () => {
    await fetch(`${process.env.REACT_APP_BASE_URL}/data/setting`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + process.env.REACT_APP_TOKEN,
      },
    })
      .then((r) => r.json().then((data) => ({ status: r.status, data: data.data })))
      .then((data) => {
        setSite(data.data[0]);
        setTitle(data.data[0].title);
      })
      .catch((err) => {
        swal(err.message, {
          icon: "error",
          button: true,
        });
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    detailSite();
  }, []);

  return (
    <HelmetProvider>
      <Helmet prioritizeSeoTags>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta content="yes" name="apple-mobile-web-app-capable" />
        <meta name="viewport" content="width=device-width, minimum-scale=1, maximum-scale=1, initial-scale=1, user-scalable=no" />
        <title>{title} - Website topup termurah dan terbaik</title>
        <meta name="application-name" content={title} />
        <meta name="copyright" content={`© 2023 by ${title}`} />
        <meta name="description" content={`Top up or buy game credits/diamonds/game voucher, fast and cheap at ${title}. ${title} is the best way to buy game credits/game vouchers.`} />
        <meta name="title" content={`${title} - Website topup termurah dan terbaik`} />
        <meta name="robots" content="noodp, noydir" />
        <meta name="theme-color" content="#ff962d" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`${title} ${title} - Cheapest and Fastest Online Game Voucher`} />
        <meta property="og:description" content={`Top up or buy game credits/diamonds/game voucher, fast and cheap at ${title}. ${title} is the best way to buy game credits/game vouchers.`} />
        <meta property="twitter:card" content="summary" />
        <meta property="twitter:site" content={`@${title.toLowerCase()}`} />
        <meta property="twitter:title" content={`${title} ${title} - Cheapest and Fastest Online Game Voucher`} />
        <meta property="twitter:description" content={`Top up or buy game credits/diamonds/game voucher, fast and cheap at ${title}. ${title} is the best way to buy game credits/game vouchers.`} />
      </Helmet>
      <div className="set-height">
        <NavbarCp data={site} />
        <Container>
          <div className="d-flex flex-column align-items-center">
            <img src={notFound} alt="Page Not Found" className="w-50" />
            <h3 className="text-dark text-center ">Page Not Found</h3>
            <span className="text-dark text-center ">Halaman tidak ditemukan. Pastikan huruf yang Anda ketik sudah benar.</span>
          </div>
        </Container>
        <FooterCp data={site} />
      </div>
    </HelmetProvider>
  );
};

export default Notfound;
