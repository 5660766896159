import React, { Component } from "react";
import NavbarCp from "../../components/NavbarCp";
import FooterCp from "../../components/FooterCp";
import FormTopup from "../../components/FormTopup";
import ChatUs from "../../partials/chatUs";
import { Container } from "react-bootstrap";
import { Helmet, HelmetProvider } from "react-helmet-async";
import swal from "sweetalert";

class Topup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.detailSite();
  }

  detailSite = async () => {
    await fetch(`${process.env.REACT_APP_BASE_URL}/data/setting`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + process.env.REACT_APP_TOKEN,
      },
    })
      .then((r) => r.json().then((data) => ({ status: r.status, data: data.data })))
      .then((data) => {
        this.setState((this.site = data.data[0]));
      })
      .catch((err) => {
        swal(err.message, {
          icon: "error",
          button: true,
        });
      });
  };

  render() {
    return (
      <HelmetProvider>
        <Helmet prioritizeSeoTags>
          <meta charset="UTF-8" />
          <meta http-equiv="X-UA-Compatible" content="IE=edge" />
          <meta content="yes" name="apple-mobile-web-app-capable" />
          <meta name="viewport" content="width=device-width, minimum-scale=1, maximum-scale=1, initial-scale=1, user-scalable=no" />
          <title>{this.state.title} - Website topup termurah dan terbaik</title>
          <meta name="application-name" content={this.state.title} />
          <meta name="copyright" content={`© 2023 by ${this.state.title}`} />
          <meta name="description" content={`Top up or buy game credits/diamonds/game voucher, fast and cheap at ${this.state.title}. ${this.state.title} is the best way to buy game credits/game vouchers.`} />
          <meta name="title" content={`${this.state.title} - Website topup termurah dan terbaik`} />
          <meta name="robots" content="noodp, noydir" />
          <meta name="theme-color" content="#ff962d" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content={`${this.state.title} ${this.state.title} - Cheapest and Fastest Online Game Voucher`} />
          <meta property="og:description" content={`Top up or buy game credits/diamonds/game voucher, fast and cheap at ${this.state.title}. ${this.state.title} is the best way to buy game credits/game vouchers.`} />
          <meta property="twitter:card" content="summary" />
          <meta property="twitter:site" content={`@${this.state.title.toLowerCase()}`} />
          <meta property="twitter:title" content={`${this.state.title} ${this.state.title} - Cheapest and Fastest Online Game Voucher`} />
          <meta property="twitter:description" content={`Top up or buy game credits/diamonds/game voucher, fast and cheap at ${this.state.title}. ${this.state.title} is the best way to buy game credits/game vouchers.`} />
        </Helmet>
        <div className="set-height">
          <NavbarCp data={this.site} />
          <Container>
            <FormTopup />
          </Container>
          <ChatUs data={this.site} />
          <FooterCp data={this.site} />
        </div>
      </HelmetProvider>
    );
  }
}

export default Topup;
